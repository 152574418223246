<template>
  <v-app-bar app :class="navClass" hide-on-scroll flat height="60">
    <!-- 手机端导航栏 -->
    <div class="d-md-none nav-mobile-container">
      <div class="" style="font-size:18px;font-weight:bold;">
        <router-link to="/">
          {{ websiteConfig['websiteAuthor'] }}
        </router-link>
      </div>
      <div style="margin-left:auto">
        <a @click="openSearch"><i class="iconfont icon-sousuo" style="font-weight: bold;" /></a>
        <a style="margin-left:10px;font-size:20px" @click="openDrawer">
          <i class="iconfont icon-daohang" style="font-weight: bold;" />
        </a>
      </div>
    </div>

    <!-- 电脑端导航栏 -->
    <div class="d-md-block d-none nav-container">
      <div class="float-left blog-title">
        <router-link to="/">
          <ruby>
            <span class="sakuraso">Pink</span>
            <span class="no">の </span>
            <span class="shironeko">pig </span>
            <rp></rp>
            <rt class="chinese-font" style="color: plum">
              猪猪侠</rt
            >
            <rp></rp>
          </ruby>
        </router-link>
      </div>
      <div class="float-right nav-title">
        <div class="menus-item">
          <a class="menu-btn" @click="openSearch">
            🔍 搜索
          </a>
        </div>
        <div class="menus-item">
          <router-link class="menu-btn" to="/">
            🏡 <span>首页</span>
          </router-link>
        </div>
        <div class="menus-item">
          <a class="menu-btn" href="/other/welcome/index.html" target="_blank">
            💕 <span>时间</span>
          </a>
        </div>
        <div class="menus-item">
          <a class="menu-btn">
            📕 日常
            <i class="iconfont icon-xiangxia expand" />
          </a>
          <ul class="menus-submenu">
<!--            <li>-->
<!--              <router-link to="/archives">-->
<!--                📚 归档-->
<!--              </router-link>-->
<!--            </li>-->
            <li>
              <router-link to="/categories">
                📖 分类
              </router-link>
            </li>
            <li>
              <router-link to="/tags">
                🔖 标签
              </router-link>
            </li>
          </ul>
        </div>
        <div class="menus-item">
          <router-link class="menu-btn" to="/albums">
            💽 <span>相册</span>
          </router-link>
        </div>
        <div class="menus-item">
          <a class="menu-btn" href="/other/photoWall/index.html" target="_blank">
            📷 <span>照片墙</span>
          </a>
        </div>
        <div class="menus-item">
          <a class="menu-btn">
            👣 历史
            <i class="iconfont icon-xiangxia expand" />
          </a>
          <ul class="menus-submenu">
            <li>
              <a class="menu-btn" href="/other/520/index.html" target="_blank">
                🌹 <span>520</span>
              </a>
            </li>
            <li>
              <a class="menu-btn" href="/other/pixel/index.html" target="_blank">
                👾 <span>像素</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="menus-item">
          <a class="menu-btn" @click="logout">
            🙌
          </a>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
  import {removeToken} from '@/utils/token'
export default {
  name: 'TopNavBar',
  data() {
    return {
      navClass: 'nav'
    }
  },
  computed: {
    websiteConfig() {
      return this.$store.state.websiteConfig
    },
    avatar() {
      return this.$store.state.avatar
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scroll)
  },
  methods: {
    scroll() {
      const that = this
      that.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (that.scrollTop > 60) {
        that.navClass = 'nav-fixed'
      } else {
        that.navClass = 'nav'
      }
    },
    openSearch() {
      this.$store.state.searchFlag = true
    },
    openDrawer() {
      this.$store.state.drawerFlag = true
    },
    openLogin() {
      this.$store.state.loginFlag = true
    },
    logout() {
      removeToken()
      this.$store.state.loginFlag = true
      this.$store.commit('logout')
      this.$toast({ type: 'success', message: '注销成功' })
    }
  }
}
</script>

<style scoped>
i {
  margin-right: 4px;
}
ul {
  list-style: none;
}

/* nav */
.nav {
  background: var(--nav-bg-color) !important;
}
.nav a {
  color: var(--nav-font-color) !important;
}
.nav .menu-btn {
  text-shadow: 0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.3);
}
.nav .menu-btn:hover {
  color: var(--blue-color) !important;
}
.nav .blog-title a {
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
}
.theme--light.nav .menus-submenu {
  box-shadow: var(--light-menus-submenu-box-shadow) !important;
  background-color: var(--light-menus-submenu-bg-color) !important;
}
.theme--light.nav .menus-submenu a {
  color: var(--light-menus-submenu-font-color) !important;
}
.theme--dark.nav .menus-submenu {
  background: var(--dark-menus-submenu-bg-color) !important;
}
.theme--dark.nav .menus-submenu a {
  color: var(--dark-menus-submenu-font-color) !important;
}

/* nav-fixed */
.nav-fixed .menus-item a, .nav-fixed .blog-title a {
  text-shadow: none;
}
.nav-fixed .menu-btn:hover {
  color: var(--blue-color) !important;
}
.theme--light.nav-fixed {
  box-shadow: var(--light-nav-fixed-box-shadow) !important;
  background: var(--light-nav-fixed-bg-color) !important;
}
.theme--light.nav-fixed a {
  color: var(--light-nav-fixed-font-color) !important;
}
.theme--dark.nav-fixed {
  background: var(--dark-nav-fixed-bg-color) !important;
}
.theme--dark.nav-fixed a {
  color: var(--dark-nav-fixed-font-color) !important;
}
.theme--light.nav-fixed .menus-submenu {
  box-shadow: var(--light-menus-submenu-box-shadow) !important;
  background-color: var(--light-menus-submenu-bg-color) !important;
}
.theme--light.nav-fixed .menus-submenu a {
  color: var(--light-menus-submenu-font-color) !important;
}
.theme--dark.nav-fixed .menus-submenu {
  background: var(--dark-menus-submenu-bg-color) !important;
}
.theme--dark.nav-fixed .menus-submenu a {
  color: var(--dark-menus-submenu-font-color) !important;
}

/* mobile nav */
.nav-mobile-container {
  width: 100%;
  display: flex;
  align-items: center;
}

/* pc nav */
.nav-container {
  font-size: 16px;
  width: 100%;
  height: 100%;
}
.blog-title, .nav-title {
  display: flex;
  align-items: center;
  height: 100%;
}
.blog-title a {
  font-size: 20px;
  font-weight: bold;
}
.menus-item {
  position: relative;
  display: inline-block;
  margin: 0 0.5rem;
}
.menus-item a {
  transition: all 0.2s;
}
.menu-btn {
  line-height: 60px;
}
.menu-btn:hover:after {
  width: 100%;
}
.menus-item a:after {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 6px;
  background-color: var(--light-blue-color);
  content: "";
  transition: all 0.3s ease-in-out;
}
.menus-item:hover .menus-submenu {
  display: block;
}
.menus-submenu {
  position: absolute;
  display: none;
  right: 0;
  width: max-content;
  margin-top: 0;
  animation: submenu 0.3s 0.1s ease both;
}
.menus-submenu:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
}
.menus-submenu a {
  line-height: 2;
  text-shadow: none;
  display: block;
  padding: 6px 14px;
}
.menus-submenu a:hover {
  background: var(--blue-color);
}
@keyframes submenu {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    filter: none;
    transform: translateY(0);
  }
}

/* 用户头像 */
.user-avatar {
  cursor: var(--globalPointer);
  height: 40px;
  width: 40px;
  margin: auto;
  border-radius: 15%;
}

.blog-title .sakuraso {
  background-color: rgba(255, 255, 255, .5);
  border-radius: 5px;
  color: pink;
  height: auto;
  line-height: 25px;
  margin-right: 0;
  padding-bottom: 0;
  padding-top: 1px;
  text-size-adjust: 100%;
  width: auto
}
.blog-title .shironeko {
  color: pink;
}
.blog-title a:hover .sakuraso {
  background-color: orange;
  color: #fff
}

.blog-title a:hover .shironeko, .blog-title a:hover rt {
  color: orange
}

.blog-title a {
  color: #464646;
  float: left;
  font-size: 25px;
  font-weight: 800;
  height: 56px;
  line-height: 56px;
  padding-left: 6px;
  padding-right: 15px;
  /* padding-top: 11px; */
  text-decoration-line: none
}

.blog-title .sakuraso, .blog-title .no {
  font-size: 25px;
  border-radius: 9px;
  padding-bottom: 2px;
  /* padding-top: 5px */
}

.blog-title .no {
  display: inline-block;
  margin-left: 5px;
  color: pink
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.blog-title a:hover .no {
  /* color: orange; */
  -webkit-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite
}

.blog-title ruby {
  ruby-position: under;
  -webkit-ruby-position: after
}

.blog-title ruby rt {
  font-size: 10px;
  transform: translateY(-15px);
  opacity: 0;
  transiton-property: opacity;
  transition-duration: .5s, .5s
}

.blog-title a:hover ruby rt {
  opacity: 1
}
.blog-title a {
  line-height: 60px;
  padding-top: 0px;
}
.chinese-font {
  font-family: 'Merriweather Sans', Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', 'WenQuanYi Micro Hei', sans-serif
}
</style>
