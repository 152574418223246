import Vue from 'vue'
import axios from 'axios'
import JSONBIG from 'json-bigint'
import {getToken} from '@/utils/token'
import errorCode from '@/utils/errorCode'
import store from '@/store'

// create instance
const instance = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',
  // 超时
  timeout: 10000
})

// 处理超过16位数字精度丢失问题
instance.defaults.transformResponse = [
  function (data) {
    if (data instanceof Blob) {
      return data
    }

    const json = JSONBIG({
      storeAsString: true
    })
    return json.parse(data)
  }
]

// 是否允许携带凭证
instance.defaults.withCredentials = true

// 设置请求拦截器
instance.interceptors.request.use(
  config => {
    // set default header
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 设置响应拦截器
instance.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    if (code === 401) {
      store.state.loginFlag = true
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      Vue.prototype.$toast({type: 'error', message: msg})
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      Vue.prototype.$toast({type: 'error', message: msg})
      return Promise.reject('error')
    } else if (code !== 200) {
      Vue.prototype.$toast({type: 'error', message: msg})
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export const get = (url, params, requestItem) => {
  return new Promise((resolve, reject) => {
    let options = {
      method: 'get',
      url: url,
      // 是与请求一起发送的 URL 参数，必须是一个简单对象或 URLSearchParams 对象
      params: params,
      // 自定义请求头
      headers: {},
      // 浏览器将要响应的数据类型，['arraybuffer', 'document', 'json', 'text', 'stream', 'blob'(浏览器专属)]
      responseType: 'json'
    }

    options = Object.assign({}, options, requestItem)
    instance(options)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const post = (url, params, requestItem) => {
  return new Promise((resolve, reject) => {
    let options = {
      method: 'post',
      url: url,
      // 作为请求体被发送的数据，[string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams]
      // 浏览器专属: FormData, File, Blob; Node 专属: Stream, Buffer
      // 可选语法，Country=China&City=Xian，只有 value 会被发送，key 则不会
      data: params,
      // 自定义请求头
      headers: {},
      // 浏览器将要响应的数据类型，['arraybuffer', 'document', 'json', 'text', 'stream', 'blob'(浏览器专属)]
      responseType: 'json'
    }

    options = Object.assign({}, options, requestItem)
    instance(options)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default {
  httpRequest() {
    return {get, post}
  }
}
