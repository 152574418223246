import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['../views/home/index'], resolve),
    meta: {
      keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  {
    path: '/articles/:articleId',
    name: 'Detail',
    component: resolve => require(['../views/article/article'], resolve)
  },
  {
    path: '/article404',
    component: resolve => require(['../views/article/articleNotFound'], resolve)
  },
  // {
  //   path: '/archives',
  //   component: resolve => require(['../views/archive/index'], resolve),
  //   meta: {
  //     title: '归档'
  //   }
  // },
  {
    path: '/tags',
    component: resolve => require(['../views/tag/index'], resolve),
    meta: {
      title: '标签'
    }
  },
  {
    path: '/tags/:tagId',
    component: resolve => require(['../views/article/articleListTag'], resolve),
    meta: {
      keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  {
    path: '/categories',
    component: resolve => require(['../views/category/index'], resolve),
    meta: {
      title: '分类'
    }
  },
  {
    path: '/categories/:categoryId',
    component: resolve => require(['../views/article/articleListCf'], resolve),
    meta: {
      keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  {
    path: '/albums',
    component: resolve => require(['../views/album/index'], resolve),
    meta: {
      title: '相册列表' ,
      keepAlive: true
    }
  },
  {
    path: '/albums/:albumId',
    component: resolve => require(['../views/album/photo'], resolve),
    meta: {
      title: '相册'
    }
  },
  // {
  //   path: '/talks',
  //   component: resolve => require(['../views/talk/index'], resolve),
  //   meta: {
  //     title: '说说列表'
  //   }
  // },
  // {
  //   path: '/talks/:talkId',
  //   component: resolve => require(['../views/talk/detail'], resolve),
  //   meta: {
  //     title: '说说'
  //   }
  // },
  // {
  //   path: '/links',
  //   component: resolve => require(['../views/link/index'], resolve),
  //   meta: {
  //     title: '友链列表'
  //   }
  // },
  // {
  //   path: '/message',
  //   component: resolve => require(['../views/message/index'], resolve),
  //   meta: {
  //     title: '弹幕'
  //   }
  // },
  // {
  //   path: '/statistics',
  //   component: resolve => require(['../views/statistics/index'], resolve),
  //   meta: {
  //     title: '统计'
  //   }
  // },
  // {
  //   path: '/user',
  //   component: resolve => require(['../views/user/index'], resolve),
  //   meta: {
  //     title: '个人中心'
  //   }
  // },
  {
    path: '/404',
    component: (resolve) => require(['../views/features/404'], resolve),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
