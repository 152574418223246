import api from '@/utils/request'

const portalApi = '/love'

export default {
  // 登录
  getCount: params => {
    return api.httpRequest().get(`${portalApi}/getCount`, {})
  },
  // 登录
  webLogin: params => {
    return api.httpRequest().post(`${portalApi}/login`, params)
  },
  // 查询博客前台信息
  querytWebConfigInfo: params => {
    return api.httpRequest().get(`/system/website/querytWebConfigInfo`, params)
  },
  // 获取文章列表
  queryArticleList: params => {
    return api.httpRequest().get(`${portalApi}/daily/list`, params)
  },
  // 获取banner列表
  queryBannerList: params => {
    return api.httpRequest().get(`${portalApi}/banner/list`, {})
  },
  // 获取文章详情
  queryArticleDetail: params => {
    return api.httpRequest().get(`${portalApi}/daily/`+params, {})
  },
  // 获取弹幕列表
  queryMessageList: params => {
    return api.httpRequest().get(`${portalApi}/queryMessageList`, params)
  },
  // 发送弹幕
  saveMessage: params => {
    return api.httpRequest().post(`${portalApi}/saveMessage`, params)
  },
  // 获取友链列表
  queryFriendLinkList: params => {
    return api.httpRequest().get(`${portalApi}/queryFriendLinkList`, params)
  },
  // 获取评论列表
  queryCommentList: params => {
    return api.httpRequest().get(`${portalApi}/comment/queryCommentList`, params)
  },
  // 保存评论
  saveComment: params => {
    return api.httpRequest().post(`${portalApi}/comment/saveComment`, params)
  },
  // 点赞评论
  likeComment: params => {
    return api.httpRequest().post(`${portalApi}/comment/likeComment`, params)
  },
  // 回复评论
  replyComment: params => {
    return api.httpRequest().post(`${portalApi}/comment/replyComment`, params)
  },
  // 删除评论
  deleteComment: params => {
    return api.httpRequest().post(`${portalApi}/comment/deleteComment`, params)
  },
  // 获取评论回复列表
  queryCommentReplyList: params => {
    return api.httpRequest().get(`${portalApi}/comment/queryCommentReplyList`, params)
  },
  // 获取轮播的说说列表
  queryBoardTalkList: params => {
    return api.httpRequest().get(`${portalApi}/queryBoardTalkList`, params)
  },
  // 获取标签云数据
  queryBoardTagList: params => {
    return api.httpRequest().get(`${portalApi}/queryBoardTagList`, params)
  },
  // 获取热门文章列表
  queryBoardHotArticleList: params => {
    return api.httpRequest().get(`${portalApi}/queryBoardHotArticleList`, params)
  },
  // 获取分类专栏列表
  queryBoardCategoryList: params => {
    return api.httpRequest().get(`${portalApi}/queryBoardCategoryList`, params)
  },
  // 获取最新评论列表
  queryBoardLastedCommentList: params => {
    return api.httpRequest().get(`${portalApi}/comment/queryBoardLastedCommentList`, params)
  },
  // 获取说说列表
  queryTalkList: params => {
    return api.httpRequest().get(`${portalApi}/queryTalkList`, params)
  },
  // 获取说说详情
  queryTalkById: params => {
    return api.httpRequest().get(`${portalApi}/queryTalkById`, params)
  },
  // 点赞说说
  likeTalk: params => {
    return api.httpRequest().post(`${portalApi}/likeTalk`, params)
  },
  // 获取标签列表
  queryTagList: params => {
    return api.httpRequest().get(`${portalApi}/tag/use`, params)
  },
  // 获取分类列表
  queryCategoryList: params => {
    return api.httpRequest().get(`${portalApi}/classification/statistics`, params)
  },
  // 获取文章归档列表
  queryArchiveList: params => {
    return api.httpRequest().get(`${portalApi}/queryArchiveList`, params)
  },
  // 获取分类详情
  queryCategoryById: params => {
    return api.httpRequest().get(`${portalApi}/classification/`+params, {})
  },
  // 获取标签详情
  queryTagById: params => {
    return api.httpRequest().get(`${portalApi}/tag/`+params, {})
  },
  // 点赞文章
  likeArticle: params => {
    return api.httpRequest().post(`${portalApi}/likeArticle`, params)
  },
  // 浏览文章
  viewArticle: params => {
    return api.httpRequest().get(`${portalApi}/viewArticle`, params)
  },
  // 根据关键字搜索文章
  searchArticleList: params => {
    return api.httpRequest().get(`${portalApi}/daily/all`, params)
  },
  // 查询 Github 贡献日历数据
  queryGithubCalendarData: params => {
    return api.httpRequest().get(`${portalApi}/queryGithubCalendarData`, params)
  },
  // 获取相册列表
  queryAlbumList: params => {
	  return api.httpRequest().get(`${portalApi}/album/all`, params)
  },
  queryAlbumInfo: params => {
    return api.httpRequest().get(`${portalApi}/album/`+params, {})
  },
  // 获取相册照片列表
  queryAlbumPhotoList: params => {
	  return api.httpRequest().get(`${portalApi}/album/photo/list`, params)
  }
}
