import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1JaGNKaj74mj4NR56gZR' +
  'TAs/8ro92/vkiqvEmfKgSMSnF8SjQweBDTFUlOxulNr5WposEhpOxMspTiokVgkf' +
  'QCaGkLn1rd2PBJMpacNajAifaHP4tOb6tfpL0OYOLt5Dsz3/oGzTDgCAfQB6Dlny' +
  '48poYYKhXSKaekQscEkGTV32EPI+Y/GDM+5JG/SszQkLtTCTp+sdbu8orrXhbVMQ' +
  'ARgd0zfCOtarxW4/iyY3TyyCdb/dmRPFYcQyc8V7sIue3ejobffRtQuXcsesJkc+' +
  'BGw5M2OQvKTL5X/Ts1c69bpILGmMuZa+suaJGHrmbAz31Fsm+nJL/tyFNWYA0lt0' +
  '+wIDAQAB'

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}
