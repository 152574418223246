<template>
  <v-footer v-if="!isMessage" app padless absolute>
    <div class="footer-wrap">
      <div class="footer-title">{{ websiteConfig['websiteIntro'] }}</div>
      <div>
        <span >
      				本网站由 <a href="https://www.upyun.com/?utm_source=lianmeng&amp;utm_medium=referral" target="_blank"> <img alt="upyun-logo" src="https://s.nmxc.ltd/sakurairo_vision/@2.5/options/upyun_logo.webp" style="display:inline-block;vertical-align:middle;width:60px;height:30px;"> 提供 CDN 加速 / 云存储 服务</a>
        </span>
      </div>
      <div>
        ©{{ websiteConfig['websiteCreateTime'] | year }} <span
        v-if="websiteConfig['websiteCreateTime'] !=  new Date().getFullYear()">- {{ new Date().getFullYear() }}</span>
        By {{ websiteConfig['websiteAuthor'] }}
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ websiteConfig['websiteRecordNo'] }}</a>
      </div>

      <!--      <div>
              <img src="../../assets/img/websitePolice.png" style="width: 0.625rem;"/>
              <a href="https://www.beian.gov.cn/" target="_blank" style="margin-left: 0.3125rem;">{{ websiteConfig['websitePoliceNo'] }}</a>
          &ensp;
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ websiteConfig['websiteRecordNo'] }}</a>
            </div> -->

    </div>
  </v-footer>
</template>

<script>
export default {
  computed: {
    isMessage() {
      return this.$route.path === '/message'
    },
    websiteConfig() {
      return this.$store.state.websiteConfig
    }
  }
}
</script>

<style scoped>
.footer-wrap {
  width: 100%;
  /*line-height: 2;*/
  position: relative;
  /*padding: 5px 0;*/
  color: #eee;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(-45deg, #ee7752, #ce3e75, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}

.footer-wrap .footer-title {
  font-size: 12px;
}

.footer-wrap a {
  color: #eee !important;
}

@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
